import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { FullViewportLayer } from "@swan-io/lake/src/components/FullViewportLayer";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { ListRightPanel, ListRightPanelContent } from "@swan-io/lake/src/components/ListRightPanel";
import {
  ColumnConfig,
  PlainListView,
  PlainListViewPlaceholder,
} from "@swan-io/lake/src/components/PlainListView";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useQuery as useFetchQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { AccountCountry, CancelStandingOrderDocument } from "../graphql/partner";
import { makeHttpRequest } from "../utils/aircap";
import { formatCurrency, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { TransferRegularWizard } from "./TransferRegularWizard";
import { WizardLayout } from "./WizardLayout";

const styles = StyleSheet.create({
  filters: {
    paddingHorizontal: 24,
  },
  filtersDesktop: {
    paddingHorizontal: 40,
  },
  cancelButton: {
    alignSelf: "flex-start",
  },
  overflowingText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  rightPanelMobile: {
    // used only for sticky tabs
    minHeight: "100%",
  },
  rightPanelDesktop: {
    ...commonStyles.fill,
  },
});

type Props = {
  accountId: string;
  accountMembershipId: string;
  accountCountry: AccountCountry;
  large: boolean;
};

type PendingTransfer = {
  amount: string;
  beneficiaryName: string;
  beneficiaryIban: string;
  label?: string;
  reference?: string;
  createdAt: string;
  id: string;

  status: "pending" | "completed" | "accepted" | "rejected";
  files?: TransferFile[];
};

export type TransferFile = {
  id: string;
  name: string;
  type: string;
  size: number;
};

type Node = PendingTransfer;

type ExtraInfo = {
  onCancel: (id: string) => void;
};

type PendingTransferPanelProps = {
  large: boolean;
  pendingTransfer: Node;
  accountMembershipId: string;
  accountCountry: AccountCountry;
  accountId: string;
};

const translateStatus = (status: PendingTransfer["status"]) =>
  match(status)
    .with("pending", () => "En attente de validation")
    .with("completed", () => "Initié")
    .with("accepted", () => "Validé")
    .with("rejected", () => "Refusé")
    .exhaustive();

const PendingTransferPanel = ({
  large,
  pendingTransfer,
  accountMembershipId,
  accountCountry,
  accountId,
}: PendingTransferPanelProps) => {
  const [isWizardVisible, setIsWizardVisible] = useState(false);

  return (
    <>
      <ScrollView
        contentContainerStyle={large ? styles.rightPanelDesktop : styles.rightPanelMobile}
      >
        <ListRightPanelContent large={large}>
          <Tile>
            <Box alignItems="center">
              <Tag
                color={match(pendingTransfer.status)
                  .with("pending", () => "warning" as const)
                  .with("completed", () => "positive" as const)
                  .with("accepted", () => "positive" as const)
                  .with("rejected", () => "negative" as const)
                  .exhaustive()}
              >
                {translateStatus(pendingTransfer.status)}
              </Tag>
            </Box>

            <Space height={8} />

            <LakeHeading level={1} variant={large ? "h1" : "h3"} align="center">
              {formatCurrency(Number(pendingTransfer.amount), "EUR")}
            </LakeHeading>

            <Space height={12} />
          </Tile>
        </ListRightPanelContent>

        <Space height={24} />

        <ListRightPanelContent large={large} style={commonStyles.fill}>
          <ScrollView style={commonStyles.fill} contentContainerStyle={commonStyles.fill}>
            <Space height={24} />

            <ReadOnlyFieldList>
              <LakeLabel
                type="viewSmall"
                label={t("recurringTransfer.details.label.shortExplanation")}
                render={() => (
                  <LakeText variant="regular" color={colors.gray[900]}>
                    {(pendingTransfer.label ?? "") || "-"}
                  </LakeText>
                )}
              />

              <LakeLabel
                type="viewSmall"
                label={t("recurringTransfer.details.label.reference")}
                render={() => (
                  <LakeText variant="regular" color={colors.gray[900]}>
                    {(pendingTransfer.reference ?? "") || "-"}
                  </LakeText>
                )}
              />

              <LakeLabel
                type="viewSmall"
                label={t("recurringTransfer.details.label.beneficiary")}
                render={() => (
                  <LakeText variant="regular" color={colors.gray[900]}>
                    {pendingTransfer.beneficiaryName}
                  </LakeText>
                )}
              />

              <LakeLabel
                type="viewSmall"
                label="IBAN"
                render={() => (
                  <LakeText variant="regular" color={colors.gray[900]}>
                    {pendingTransfer.beneficiaryIban}
                  </LakeText>
                )}
              />

              {pendingTransfer.files?.map(file => (
                <LakeLabel
                  type="viewSmall"
                  label="Fichier"
                  actions={
                    <LakeTooltip describedBy="copy" togglableOnFocus={true} content="Télécharger">
                      <Link
                        to={`${__env.CLIENT_AIRCAP_API_URL}/download/${accountMembershipId}/${file.id}?fileName=${file.name}`}
                        target="blank"
                      >
                        <Icon color={colors.gray[900]} size={21} name="arrow-download-filled" />
                      </Link>
                    </LakeTooltip>
                  }
                  render={() => (
                    <>
                      <Box direction="row" alignItems="center">
                        <Icon name={"document-regular"} size={16} />
                        <Space width={8} />

                        <LakeText variant="regular" color={colors.gray[900]}>
                          {file.name}
                        </LakeText>
                      </Box>
                    </>
                  )}
                />
              ))}
            </ReadOnlyFieldList>
          </ScrollView>
        </ListRightPanelContent>
      </ScrollView>

      <FullViewportLayer visible={isWizardVisible}>
        <WizardLayout
          title={t("transfer.newTransfer")}
          onPressClose={() => setIsWizardVisible(false)}
        >
          {({ large }) => (
            <TransferRegularWizard
              large={large}
              accountCountry={accountCountry}
              accountId={accountId}
              accountMembershipId={accountMembershipId}
              initialBeneficiary={{
                kind: "new",
                save: false,
                iban: pendingTransfer.beneficiaryIban,
                name: pendingTransfer.beneficiaryName,
              }}
              initialDetails={{
                amount: { value: pendingTransfer.amount, currency: "EUR" },
                label: pendingTransfer.label,
                reference: pendingTransfer.reference,
              }}
              externalReference={pendingTransfer.id}
              onPressClose={() => setIsWizardVisible(false)}
            />
          )}
        </WizardLayout>
      </FullViewportLayer>

      {pendingTransfer.status === "accepted" && (
        <>
          <Space height={24} />

          <ListRightPanelContent large={large}>
            <LakeButton
              onPress={() => setIsWizardVisible(true)}
              mode="primary"
              color="partner"
              style={styles.cancelButton}
            >
              Exécuter
            </LakeButton>
          </ListRightPanelContent>
        </>
      )}
    </>
  );
};

const columns: ColumnConfig<Node, ExtraInfo>[] = [
  {
    id: "beneficiaryName",
    title: t("recurringTransfer.table.recipient"),
    width: "grow",
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { beneficiaryName } }) => (
      <Cell>
        <LakeHeading variant="h5" level={3} numberOfLines={1}>
          {beneficiaryName}
        </LakeHeading>
      </Cell>
    ),
  },
  {
    id: "beneficiaryIban",
    title: "IBAN",
    width: 260,
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { beneficiaryIban } }) => (
      <Cell>
        <LakeHeading variant="h5" level={3} numberOfLines={1}>
          {beneficiaryIban}
        </LakeHeading>
      </Cell>
    ),
  },
  {
    id: "label",
    title: "Label",
    width: "grow",
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { label } }) => <TextCell text={(label ?? "") || "-"} />,
  },
  {
    id: "reference",
    title: "Référence",
    width: "grow",
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { reference } }) => <TextCell text={(reference ?? "") || "-"} />,
  },
  {
    id: "amount",
    title: t("recurringTransfer.table.amount"),
    width: 150,
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { amount } }) => (
      <TextCell variant="medium" text={`-${formatCurrency(Number(amount), "EUR")}`} />
    ),
  },
  {
    id: "status",
    title: "Statut",
    width: 250,
    renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
    renderCell: ({ item: { status } }) => (
      <Cell align="right">
        <Tag
          color={match(status)
            .with("pending", () => "warning" as const)
            .with("completed", () => "positive" as const)
            .with("accepted", () => "positive" as const)
            .with("rejected", () => "negative" as const)
            .exhaustive()}
        >
          {translateStatus(status)}
        </Tag>
      </Cell>
    ),
  },
  {
    id: "actions",
    title: t("recurringTransfer.table.actions"),
    width: 100,
    renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
    renderCell: () => (
      <Cell align="right">
        <Space width={8} />
        <Icon name="chevron-right-filled" size={16} color={colors.gray[500]} />
      </Cell>
    ),
  },
];

const smallColumns: ColumnConfig<Node, ExtraInfo>[] = [
  {
    id: "recipient",
    title: t("recurringTransfer.table.recipient"),
    width: "grow",
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { beneficiaryName, amount } }) => (
      <Cell align="left">
        <Space width={12} />

        <View style={commonStyles.fill}>
          <LakeText variant="smallRegular" style={styles.overflowingText}>
            {beneficiaryName}
          </LakeText>

          <LakeText variant="medium" color={colors.gray[900]} style={styles.overflowingText}>
            {amount != null
              ? formatCurrency(Number(amount), "EUR")
              : t("recurringTransfer.table.fullBalanceTransfer")}
          </LakeText>
        </View>
      </Cell>
    ),
  },
  {
    id: "nextExecutionDate",
    title: t("recurringTransfer.table.nextExecution"),
    width: 200,
    renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
    renderCell: ({ item: { status } }) => (
      <Cell align="right">
        <Tag
          color={match(status)
            .with("pending", () => "warning" as const)
            .with("completed", () => "positive" as const)
            .with("accepted", () => "positive" as const)
            .with("rejected", () => "negative" as const)
            .exhaustive()}
        >
          {status}
        </Tag>
      </Cell>
    ),
  },
  {
    id: "actions",
    title: t("recurringTransfer.table.actions"),
    width: 36,
    renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
    renderCell: () => (
      <Cell align="right">
        <Icon name="chevron-right-filled" size={16} color={colors.gray[500]} />
      </Cell>
    ),
  },
];

const keyExtractor = (item: Node) => item.id;

const PAGE_SIZE = 20;

export const PendingTransferList = ({
  accountMembershipId,
  accountCountry,
  accountId,
  large,
}: Props) => {
  const route = Router.useRoute(["AccountPaymentsPendingTransferDetailsArea"]);

  const [cancelPendingTransfer, cancelResult] = useMutation(CancelStandingOrderDocument);

  const {
    isPending,
    isRefetching,
    refetch: reload,
    data,
  } = useFetchQuery({
    queryKey: ["transfers"],
    queryFn: async () => {
      return makeHttpRequest({
        url: `/transfers?accountMembershipId=${accountMembershipId}`,
        method: "GET",
        type: "json",
      }).mapOk(value => value as PendingTransfer[]);
    },
  });

  const isLoading = isPending || isRefetching;

  const activePendingTransferId =
    route?.name === "AccountPaymentsPendingTransferDetailsArea"
      ? route.params.pendingTransferId
      : null;

  const closeRightPanel = () =>
    Router.push("AccountPaymentsPendingTransferList", { accountMembershipId });

  const openDetails = (pendingTransferId: string) =>
    Router.push("AccountPaymentsPendingTransferDetailsRoot", {
      accountMembershipId,
      pendingTransferId,
    });

  const [pendingTransferToCancelId, setPendingTransferToCancelId] = useState<string | null>(null);

  const onCancelPendingTransfer = () => {
    if (pendingTransferToCancelId != null) {
      cancelPendingTransfer({ id: pendingTransferToCancelId })
        .mapOk(data => data.cancelStandingOrder)
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(async () => {
          closeRightPanel();
          setPendingTransferToCancelId(null);
          await reload();
        })
        .tapError(error => {
          showToast({ variant: "error", error, title: translateError(error) });
        });
    }
  };

  const extraInfo = useMemo<ExtraInfo>(
    () => ({
      onCancel: setPendingTransferToCancelId,
    }),
    [],
  );

  return (
    <>
      <Box
        direction="row"
        alignItems="center"
        style={[styles.filters, large && styles.filtersDesktop]}
      >
        <LakeButton
          ariaLabel={t("common.refresh")}
          mode="secondary"
          size="small"
          icon="arrow-counterclockwise-filled"
          loading={isLoading}
          onPress={() => {
            reload().catch(error => {
              console.error(error);
            });
          }}
        />
      </Box>

      <Space height={24} />

      {isLoading ? (
        <PlainListViewPlaceholder count={PAGE_SIZE} headerHeight={48} rowHeight={56} />
      ) : (
        data?.match({
          Error: error => <ErrorView error={error} />,
          Ok: transfers => (
            <>
              <PlainListView
                withoutScroll={!large}
                keyExtractor={keyExtractor}
                groupHeaderHeight={48}
                headerHeight={48}
                rowHeight={56}
                data={transfers}
                activeRowId={activePendingTransferId ?? undefined}
                extraInfo={extraInfo}
                getRowLink={({ item }) => <Pressable onPress={() => openDetails(item.id)} />}
                columns={columns}
                smallColumns={smallColumns}
                renderEmptyList={() => (
                  <>
                    <LakeText align="center" variant="medium" color={colors.gray[900]}>
                      {t("recurringTransfer.empty.title")}
                    </LakeText>

                    <Space height={12} />

                    <LakeText align="center" variant="smallRegular" color={colors.gray[700]}>
                      {t("recurringTransfer.empty.subtitle")}
                    </LakeText>
                  </>
                )}
                loading={{
                  isLoading,
                  count: PAGE_SIZE,
                }}
              />

              <ListRightPanel
                keyExtractor={keyExtractor}
                items={transfers}
                activeId={activePendingTransferId}
                onActiveIdChange={openDetails}
                onClose={closeRightPanel}
                closeLabel={t("common.closeButton")}
                previousLabel={t("common.previous")}
                nextLabel={t("common.next")}
                render={(item, large) => (
                  <PendingTransferPanel
                    large={large}
                    pendingTransfer={item}
                    accountMembershipId={accountMembershipId}
                    accountCountry={accountCountry}
                    accountId={accountId}
                  />
                )}
              />
            </>
          ),
        })
      )}

      <LakeModal
        visible={pendingTransferToCancelId != null}
        icon="subtract-circle-regular"
        color="negative"
        onPressClose={() => setPendingTransferToCancelId(null)}
        title={t("recurringTransfer.confirmCancel.title")}
      >
        <LakeText>{t("recurringTransfer.confirmCancel.message")}</LakeText>
        <Space height={48} />

        <LakeButton
          color="negative"
          loading={cancelResult.isLoading()}
          onPress={onCancelPendingTransfer}
        >
          {t("recurringTransfer.confirmCancel.cta")}
        </LakeButton>
      </LakeModal>
    </>
  );
};
