import { BadStatusError, emptyToError, Request } from "@swan-io/request";

import { isNullish } from "@swan-io/lake/src/utils/nullish";

import { getLocation } from "@swan-io/chicane";
import { badStatusToError } from "@swan-io/request";
import { Router } from "./routes";

export const makeHttpRequest = <T extends "json" | "text" = "text">({
  url,
  method,
  type,
  body,
}: {
  url: string;
  method: "GET" | "POST";
  type: T;
  body?: BodyInit | null;
}) => {
  return Request.make<T>({
    url: `${__env.CLIENT_AIRCAP_API_URL}${url}`,
    method,
    type,
    credentials: "include",
    body,
  })
    .mapOkToResult(badStatusToError)
    .mapOkToResult(emptyToError)
    .tapError(error => {
      if (
        isNullish(Router.getRoute(["ProjectLogin"])) &&
        error instanceof BadStatusError &&
        error.status === 401
      ) {
        window.location.replace(
          Router.ProjectLogin({
            sessionExpired: "true",
            redirectTo: getLocation().toString(),
          }),
        );
      }
    });
};
